<template>
  <div id="adherence-map">
    <CardHeader
      title="Mapa de aderência"
      :showPeriod="false"
      :options="[]"
      class="mb-3"
    />
    <b-row no-gutters class="adherence-map-container">
      <TreatmentCalendar
        :attributes="calendarAttributes"
        @dayclick="changeCalendarDay"
        @pageChange="changeCalendarPage"
        :legends="legends"
      />

      <b-col lg="8" sm="12" class="ml-auto">
        <div class="d-flex justify-content-start align-items-center mb-4 mt-2">
          <span class="day-content" :class="completeDate.color">
            <span class="day-number mr-2" :class="completeDate.color">
              {{ completeDate.dayNumber }}
            </span>
            {{ completeDate.month }},
            {{ completeDate.day }}
          </span>

          <div class="ml-auto">
            <multiselect
              :showLabels="false"
              :searchable="true"
              :allow-empty="false"
              label="label"
              class="mx-auto"
              v-model="medication"
              :options="medications"
              placeholder="Selecione uma medicação"
            >
              <template slot="caret">
                <ChevronDown class="chevron" />
              </template>
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.label }}
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
          </div>
        </div>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          ref="scroll"
          style="min-height: 60%"
        >
          <b-row
            class="card-container justify-content-center"
            no-gutters
            v-if="filteredAdherences.length"
          >
            <b-col
              cols="12"
              v-for="adherence in filteredAdherences"
              :key="adherence.id"
              class="col-symptom"
            >
              <PatientMedicationCard
                :medicine="adherence.userMedication.medication.medicine"
                :eyes="adherence.userMedication.eyes"
                :timesADay="adherence.userMedication.times_a_day"
                :disease="adherence.userMedication.userTreatment.disease"
                :status="adherence.userMedication.userTreatment.status"
                :dosage="adherence.dosage"
                :dripped="adherence.status === 'DRIPPED'"
                :percentage="parseFloat(adherence.successInMonth)"
                class="ml-2"
              />
            </b-col>
          </b-row>
          <b-row v-else class="justify-content-center">
            <no-filters-results :message="noFilterMessage" />
          </b-row>
        </perfect-scrollbar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'AdherenceMap',
  components: {
    CardHeader: () => import('@/layouts/InvoicingTiss/Dashboard/CardHeader'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    TreatmentCalendar: () =>
      import('@/components/Glauco/Dashboard/Patient/TreatmentCalendar'),
    PatientMedicationCard: () =>
      import('@/components/Glauco/Dashboard/Patient/PatientMedicationCard'),
    NoFiltersResults: () => import('@/components/General/noFilterResults')
  },
  data() {
    return {
      date: this.moment().format('YYYY-MM-DD'),
      patientId: this.$route.params.patientId,
      legends: [
        {
          color: 'blue',
          text: 'Todos os medicamentos foram utilizados'
        },
        {
          color: 'orange',
          text: 'Um ou mais medicamentos não foram utilizados'
        },
        {
          color: 'neutral',
          text: 'Nenhum medicamento foi utilizado'
        }
      ],
      adherences: [],
      medication: null,
      adherencesInDay: []
    }
  },
  computed: {
    calendarAttributes() {
      return this.adherences.map(adherence => {
        return {
          key: adherence.id,
          highlight: {
            color: adherence.color ?? 'gray',
            fillMode: 'light'
          },
          content: 'white',
          dates: new Date(this.moment(adherence.date))
        }
      })
    },
    filteredAdherences() {
      if (this.medication?.value) {
        return this.adherencesInDay.filter(
          adherence =>
            adherence.userMedication.medication.id === this.medication.value
        )
      }
      return this.adherencesInDay
    },
    completeDate() {
      return {
        dayNumber: this.moment(this.date).format('DD'),
        day: this.moment(this.date).format('dddd').toUpperCase(),
        month: this.moment(this.date).format('MMMM').toUpperCase(),
        color:
          this.calendarAttributes.find(
            adherence =>
              this.moment(adherence.dates).format('YYYY-MM-DD') === this.date
          )?.highlight?.color || 'gray'
      }
    },
    medications() {
      const medications = this.adherencesInDay.map(adherence => {
        return {
          label: adherence.userMedication.medication.medicine,
          value: adherence.userMedication.medication.id
        }
      })
      medications.unshift({ label: 'Todas', value: null })
      return [...new Set(medications.map(JSON.stringify))].map(JSON.parse)
    },
    noFilterMessage() {
      if (this.medication?.label)
        return `Nenhum medicamento de ${this.medication.label.toLowerCase()} registrado`
      return 'Nenhum medicamento registrado'
    }
  },
  async created() {
    await this.getPatientAdherenceInMonth()
    await this.getPatientAdherenceInDay()
  },
  methods: {
    async getPatientAdherenceInMonth(date = this.date) {
      try {
        const response = await this.api.getPatientAdherenceInMonth(
          this.patientId,
          date
        )
        this.adherences = response.data
      } catch (error) {
        this.$toast.error('Erro ao buscar aderência do paciente')
      }
    },
    async getPatientAdherenceInDay() {
      try {
        const response = await this.api.getPatientAdherenceInDay(
          this.patientId,
          this.date
        )
        this.adherencesInDay = response.data
      } catch (error) {
        this.$toast.error('Erro ao buscar aderência do paciente')
      }
    },
    async changeCalendarDay(date) {
      this.date = date.id
      await this.getPatientAdherenceInDay()
    },
    async changeCalendarPage(date) {
      let newDate = new Date(date.year, date.month - 1, 1)
      newDate = this.moment(newDate).format('YYYY-MM-DD')
      if (
        this.moment(newDate).format('MM') !==
        this.moment(this.date).format('MM')
      ) {
        await this.getPatientAdherenceInMonth(newDate)
        this.date = newDate
        await this.getPatientAdherenceInDay()
      }
    },
    changeMedication(medication) {
      this.option = medication.value
    }
  }
}
</script>

<style lang="scss">
#adherence-map {
  padding: 30px 24px;
  margin: 0 auto;
  background-color: var(--neutral-000);
  border-radius: 8px;
  margin-top: 2%;
  margin-bottom: 2%;

  .title {
    font-weight: 700;
    font-family: 'Red Hat Display';
    font-size: min(1.2vw, 16px);
  }

  .adherence-map-container {
    padding: 15px;
    border: 1px solid var(--neutral-200);
    border-radius: 8px;

    .day-content {
      &.orange {
        color: var(--orange);
      }

      &.gray {
        color: var(--neutral-200);
      }

      &.blue {
        color: var(--blue-500);
      }
      .day-number {
        width: fit-content;
        border-radius: 16px;
        padding: 10px;
        color: var(--neutral-000);

        &.orange {
          background-color: var(--orange);
        }

        &.gray {
          background-color: var(--neutral-200);
        }

        &.blue {
          background-color: var(--blue-500);
        }
      }
    }

    .card-container {
      max-height: 420px;
    }
  }
}
</style>
